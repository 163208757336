<template>
  <div>
    <header-slot />
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        v-for="route in routes"
        :key="route.id"
        :to=" { name : route.routeName } "
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == route.routeName"
        :link-classes="['px-3',bgTabsNavs]"
      >
        {{ route.description.toUpperCase() }}
      </b-nav-item>
    </b-nav>
    <template>
      <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view :key="$route.name" />
      </b-card>
    </template>
  </div>
</template>

<script>
import data from '@/views/crm/views/manage-commissions/db.json'

export default {
  name: 'ManageCommissions',
  data() {
    return {
      routes: data,
    }
  },
}
</script>

<style scoped>

</style>
